const statusSettings = {
  breking_duel: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "Acordo Quebrado",
  },
  waiting_payment: {
    color: "#7D3300",
    backgroundColor: "#FDE3D1",
    label: "Pendente",
  },
  paid: {
    color: "#2F7D00",
    backgroundColor: "#E2FDD1",
    label: "Acordo quitado",
  },
  viewed: {
    color: "#00577D",
    backgroundColor: "#D1E3FD",
    label: "Acordo consultado",
  }, 
  generated: {
    color: "#00577D",
    backgroundColor: "#D1E3FD",
    label: "Acordo emitido",
  },
  pending: {
    color: "#00577D",
    backgroundColor: "#D1E3FD",
    label: "Acordo em progresso",
  },
  contacted: {
    color: "#00577D",
    backgroundColor: "#D1E3FD",
    label: "Contato realizado",
  },
  cpc: {
    color: "#00577D",
    backgroundColor: "#D1E3FD",
    label: "CPC",
  },
  new_condition: {
    color: "#7D3300",
    backgroundColor: "#FDE3D1",
    label: "Nova condição",
  },
  no_interest: {
    color: "#7D3300",
    backgroundColor: "#FDE3D1",
    label: "Sem interesse na proposta",
  },
  agreement_broken: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "Acordo quebrado",
  },
  promise_to_pay: {
    color: "#7D3300",
    backgroundColor: "#FDE3D1",
    label: "Promessa de pagamento",
  },
  scheduled_contact: {
    color: "#7D3300",
    backgroundColor: "#FDE3D1",
    label: "Contato agendado",
  },
  suspected_fraud: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "Suspeita de fraude",
  },
  indirect_payment: {
    color: "#7D7100",
    backgroundColor: "#FDF6D1",
    label: "Pagamento indireto",
  },
  cpe: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "CPE",
  },
  deceased: {
    color: "#000000",
    backgroundColor: "#D3D3D3",
    label: "Falecido",
  },
  collections_suspended: {
    color: "#4D007D",
    backgroundColor: "#E4D1FD",
    label: "Cobrança suspensa",
  },
  not_return: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "Sem retorno",
  },
  without_terms_payment: {
    color: "#7D0000",
    backgroundColor: "#FDD4D1",
    label: "Sem Cond. Pgto",
  },
  "Dentro do prazo": {
    color: "#2F7D00",
    backgroundColor: "#E4FDD1",
    label: "Dentro do prazo",
  },
  "Expirado": {
    color: "#533B17",
    backgroundColor: "#F9F1E7",
    label: "Expirado",
  },
}

const parseStatus = status => (
  <span
    style={{
      color: statusSettings[status].color,
      backgroundColor: statusSettings[status].backgroundColor,
      padding: "5px 16px",
      borderRadius: "3px",
      display: "block",
      textAlign: "center",
    }}
  >
    {statusSettings[status].label}
  </span>
)

export default parseStatus;
