import axios from 'axios';
import axiosInstance from './axiosInstance';

const baseUrl = 'https://api.kollecta.io';

const getOffers = async (personalNumber) => {
  return await axios.get(`${baseUrl}/api/past-dues-unlocked?personalNumber=${personalNumber}`)
}

const dealOffer = async (offerId, paymentMethod, installmentDay = null) => {
  const queryString = installmentDay ? `?paymentMethod=${paymentMethod}&installmentDay=${installmentDay}` : `?paymentMethod=${paymentMethod}`
  return await axios.get(`${baseUrl}/api/past-dues-deal/${offerId}${queryString}`)
}

const uploadFile = async (files) => {
  return await axiosInstance.post(`/upload`, files)
}

const auth = async (data) => {
  return await axios.post(`${baseUrl}/auth/login`, data)
}

const getSummaries = async () => {
  return await axiosInstance.get(`${baseUrl}/api/summaries`)
}

const getPastDues = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues`)
}

const getPastDuesById = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues/${id}`)
}

const getQueueNext = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-queue-next`)
}

const getByUser = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-user`)
}

const getByUserScheduledContact = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-status/scheduled_contact`)
}

const resetQueueByUser = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-reset-attendance/${id}`)
}


const getSummariesLast30Days = async () => {
  return await axiosInstance.get(`${baseUrl}/api/last-30-days`)
}

const getCurrencyValue = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/credits/${id}`)
}

const getWallet = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet/${id}`)
}

const getFilters = async () => {
  return await axiosInstance.get(`${baseUrl}/api/datasets`)
}

const postWallets = async (id, filters, file) => {
  return await axiosInstance.post(`${baseUrl}/api/${id}/wallet?datasets=${filters}`, file)
}

const postWalletsSingle = async (id, filters, document) => {
  return await axiosInstance.post(`${baseUrl}/api/${id}/wallet-single?datasets=${filters}`, { document })
}

const postWalletsConfirm = async (id, companyId, filters, file) => {
  return await axiosInstance.post(`${baseUrl}/api/confirm/${id}/${companyId}/wallet?datasets=${filters}`, file)
}

const postWalletsConfirmSingle = async (id, companyId, filters, document) => {
  return await axiosInstance.post(`${baseUrl}/api/confirm/${id}/${companyId}/wallet-single?datasets=${filters}`, { document })
}
const postCreditsUpdate = async (data) => {
  return await axiosInstance.patch(`${baseUrl}/api/credits`, data)
}

const getCredits = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/credits/${id}`)
}

const naoSeiDownloadWallet = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet/download/${id}`)
}

const downloadWalletSingle = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet-single/download/${id}`)
}

const getCompanySettings = async (siteUrl) => {
  return await axiosInstance.get(`${baseUrl}/api/company-by-site-url?siteUrl=${siteUrl}`)
}

const postSendEmail = async (data) => {
  return await axios.post(`${baseUrl}/api/crm/send-email`, data)
}

const updatePastDue = async (id, data) => {
  return await axios.put(`${baseUrl}/api/past-dues/status/${id}`, data)
}

const updateAllPastDueData = async (id, data) => {
  return await axiosInstance.put(`${baseUrl}/api/past-dues/${id}`, data)
}

const getpastDueLogs = async () => {
  return await axiosInstance.get(`${baseUrl}/api/pastdue-logs`)
}

const getpastDueLogsByDocument = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/pastdue-logs`, data)
}

const createPastdueLog = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/create-pastdue-logs`, data)
}

const getPastDueByContract = async (contract) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-contract/${contract}`)
}

const getMonthlyGoals = async (userId) => {
  return await axiosInstance.get(`${baseUrl}/api/users-monthly-goals/${userId}`)
}

const getMonthlyGoalsByMonthly = async (userId, month) => {
  return await axiosInstance.get(`${baseUrl}/api/users-monthly-goals-monthly/${userId}/${month}`)
}

const getByUserStatus = async (status) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-status/${status}`)
}

// contracts
const getPastDuesByDocument = async (personalNumber, companyDisplayName) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-document?personalNumber=${personalNumber}&companyDisplayName=${companyDisplayName}`)
}

const getTransactionsByDocument = async (personalNumber, companyDisplayName) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-transactions?personalNumber=${personalNumber}&companyDisplayName=${companyDisplayName}`)
}

// pastDues by Group
const getPastDuesByGroup = async (page) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-group-company?page=${page}&limit=5000`)
}

// creditor names
const getUniqueCompanyDisplayNames = async () => {
  return await axiosInstance.get(`${baseUrl}/api/creditors`);
}

// chartPie
const getPieChartData = async () => {
  return await axiosInstance.get(`${baseUrl}/api/charts/pie`);
}

// chartLine
const getLineChartData = async () => {
  return await axiosInstance.get(`${baseUrl}/api/charts/line`);
}

// measurements pastDues
const getPastDuesMeasurements = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/past-dues/creditors`, data);
}

export {
  getOffers,
  dealOffer,
  uploadFile,
  auth,
  getByUser,
  getByUserScheduledContact,
  getPastDuesById,
  getSummaries,
  getSummariesLast30Days,
  getCurrencyValue,
  getCredits,
  getPastDues,
  getPastDueByContract,
  getWallet,
  getFilters,
  getQueueNext,
  postWallets,
  postWalletsSingle,
  postCreditsUpdate,
  postWalletsConfirm,
  postWalletsConfirmSingle,
  naoSeiDownloadWallet,
  downloadWalletSingle,
  getCompanySettings,
  postSendEmail,
  updatePastDue,
  getpastDueLogs,
  getpastDueLogsByDocument,
  createPastdueLog,
  updateAllPastDueData,
  resetQueueByUser,
  getMonthlyGoals,
  getMonthlyGoalsByMonthly,
  getByUserStatus,
  getPastDuesByDocument,
  getTransactionsByDocument,
  getPastDuesByGroup,
  getUniqueCompanyDisplayNames,
  getPieChartData,
  getLineChartData,
  getPastDuesMeasurements,
}
