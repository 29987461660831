import styles from './style.module.css';


const Pagination = ({ currentPage, setCurrentPage, totalItems, itemsPerPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
        setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrev = () => {
      if (currentPage > 1) {
          setCurrentPage(prevPage => prevPage - 1);
      }
  };

  return (
    <nav className={styles.wrapper}>
      <ul className={styles.pagination}>
        <li className={`${styles.pageItem} ${currentPage === 1 ? styles.disabled : ''}`}>
          <a onClick={handlePrev} className={styles.pageLink}>
              Anterior
          </a>
        </li>

        {pageNumbers.map(number => (
          <li key={number} className={`${styles.pageItem} ${number === currentPage ? styles.active : ''}`}>
              <a onClick={() => handlePageChange(number)} className={styles.pageLink}>
                  {number}
              </a>
          </li>
        ))}

        <li className={`${styles.pageItem} ${currentPage === Math.ceil(totalItems / itemsPerPage) ? styles.disabled : ''}`}>
          <a onClick={handleNext} className={styles.pageLink}>
            Próximo
          </a>
        </li>
      </ul>
    </nav>

  );
};

export default Pagination;

