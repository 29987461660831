import React from 'react'
import styles from './style.module.css'

const RadioButtonOffer = ({
  value,
  onChange,
  children,
  name,
  checked
}) => {
  return (   
    <label
      htmlFor={value}
    >
      <input
        defaultChecked={null}
        type="radio"
        id={value}
        name={name}
        className={styles.cardInputElement}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <div className={styles.cardInput}>
        <div className={styles.radioCircle}>
          <div className={styles.radioDot} />
        </div>
        <div className={styles.contentRadio}>
          {children}
        </div>
      </div>
    </label>
  )
}

export default RadioButtonOffer
