import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './style.module.css';
import { applySpec, pipe, pathOr, reduce } from 'ramda';
import eye from '../../assets/images/eye.svg';
import Modal from '../Modal';
import { getpastDueLogsByDocument } from '../../services/kollectaApi';
import moment from 'moment';
import whatsappImage from '../../assets/images/whatsapp.svg';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';

const Table = ({
  title,
  downloadText,
  tbSettings,
  data,
}) => {
  const [receivedData, setReceivedData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const handleCellClick = (item) => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Modal de informações devedor'
    });
    setSelectedItem(item);
    if (item !== null) {
      navigate('/dashboard/details', { state: { data: item } });
    }
  }
  
const buildItem = (acc, next) => {
  if (!acc[next]) {
    acc = {
      ...acc,
      [next.path]: (item) => (
        <td
          key={`${next.path}-${pathOr('-', [next.path], item)}`}
          className={classNames([
            styles.tbCel,
            { [styles.tbAlignCenter]: next.center }, next.path === 'details' ? styles.cursor : ''
          ])}
          onClick={next.path === 'details' ? () => handleCellClick(item, next.path) : null}
        >
          {next.columnFormat ? next.columnFormat(pathOr('-', [next.path], item)) : pathOr('-', [next.path], item)}
        </td>
      )
    }

    if (next.path === 'details') {
      acc[next.path] = (item) => (
        <th className={styles.details}>
          <img onClick={() => handleCellClick(item)} src={eye} alt="Botão para página de detalhe" />
        </th>
      );
    }
  }
  return acc;
}
  
  const buildTdItem = (item) => {
    let items = []
    for(let key in item) {
      items = [...items, item[key]]
    }
    return items
  }
  
  const buildCollumn = (settings, receivedData) => {
    const paths = pipe(reduce(buildItem, {}))(settings);
    const result =  receivedData
    .map(applySpec(paths))
    .map(buildTdItem);
      return result;
  }

  const buildDataTb = buildCollumn(tbSettings, receivedData);

  const RenderHeaders = ({ headerName, center }, index) => (
    <th 
      key={`${headerName}-${index}`}
      className={classNames([
      styles.tbCel,
      { [styles.tbAlignCenter]: center },
    ])}
    >{headerName}</th>
  )

  useEffect(() => {
    setReceivedData(data);
  }, [data, receivedData]);
 
  return (
    <div className={styles.tableContainer}>
      <table className={styles.tableCustom}>
        <thead className={styles.tbHeader}>
          <tr>
            {tbSettings.map(RenderHeaders)}
          </tr>
        </thead>
        <tbody>
          {buildDataTb.map((item, index) => (
            <tr key={index}>
              {item}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
