import React, { useState } from 'react';
import styles from './style.module.css';


const InputSimple = ({ onValueChange, placeholder, isCurrency }) => {
  const [value, setValue] = useState('');  // Raw (numeric) value
  const [displayValue, setDisplayValue] = useState('');  // Formatted value for display

  const formatCurrency = (value) => {
    if (!value) {
      return '';
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(parseFloat(value) / 100); // Divide por 100 para converter para reais
  };

  const parserCurrencyValue = (value = '') => {
    if (value === null) {
      value = 0;
    }
    if (typeof value === 'object' && value.result) {
      value = value.result;
    }

    let num;
    if (typeof value !== 'string') {
      num = parseFloat(value);
    } else {
      num = parseFloat(value.replace(/[^\d.,-]+/g, '').replace(',', '.'));
    }

    return Math.round(num * 100);  // Multiply by 100 to get cents as integer
  }

  const handleFocus = () => {
    if (value) {
      setDisplayValue(value.replace('.', ','));  // Show the raw value when input is focused, with comma as decimal separator
    } else {
      setDisplayValue('');
    }
  }

  const handleBlur = () => {
    if (isCurrency) {
      const parsedValue = parserCurrencyValue(value);
      setDisplayValue(parsedValue ? formatCurrency(parsedValue) : '');  // Format value when input is blurred
    } else {
      setDisplayValue(value);
    }
  }
  
  const handleChange = (event) => {
    const inputValue = event.target.value;

    if (!inputValue || /^[0-9.,]+$/.test(inputValue)) {
      const numericValue = inputValue.replace(',', '.');  // Replace comma with dot for number conversion
      setValue(numericValue);
      setDisplayValue(inputValue);

      // Pass parsed numeric value to the parent component
      if (onValueChange) {
        onValueChange(parserCurrencyValue(numericValue));
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <input 
        type="text"
        value={displayValue} 
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputSimple;

