import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { dealOffer } from '../../../services/kollectaApi'
import { useSelector } from 'react-redux'
import { updatePastDue, postSendEmail } from '../../../services/kollectaApi.js'

import PaymentMethodsContainer from '../../../Container/Mobile/PaymentMethods'
import { setPastdueTransaction } from '../../../Redux/Reducers/PastDueTransaction'

const PaymentMethods = () => {
  const [payment, setPayment] = useState('boleto');
  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { offerId, installmentDay } = useParams();

  const  getFirstName = (fullName) =>  {
    const names = fullName.split(' ');
    return names[0];
  }

  const sendEmail = async (data) => {
    try {
        await postSendEmail({
          id: data.id,
          email: data.email,
          firstName: getFirstName(data.customerName),
          credor: data.companyDisplayName,
          boletoUrl: data.pastDueTransactions[0].boleto_url,
          personalNumber: data.personalNumber,
          companyId: data.companyId,
          company: data.companyDisplayName,
          emailConfirmed: data.emailConfirmed,
        })

    } catch(error) {
      console.log('Error to send email', error);
    }
  }

  const goToChoose = async (emailConfirmed) => {
    setLoading(true);
    try {
      const { data = {}} = await dealOffer(offerId, payment, installmentDay);
      dispatch(setPastdueTransaction(data));
      const pastDueId = pastDue.id;
      const updatedData = { ...data, emailConfirmed };
      if (pastDue.status !== 'generated') {
        const status = { status: "generated" };
        try {
          await updatePastDue(pastDueId, status);
          await sendEmail(updatedData);
          setLoading(false);
          return navigate(`method/boleto/billetmethods/method/boleto`);
        } catch (error) {
          setLoading(false);
          return setError(error);
        }
      }
      setLoading(false);
      return setError('generated');
    } catch (error) {      
      console.log('Error:', error);
      setError(error);
      setLoading(false);
    }
  }

  const handleChange = ({ target }) => {
    setPayment(target.value);
  }

  useEffect(() => {
  }, [error]);

  return (
    <PaymentMethodsContainer
      goToPayment={goToChoose}
      onChange={handleChange}
      installmentDay={installmentDay}
      pastDue={pastDue}
      error={error}
      loading={loading}
    />
  )
}

export default PaymentMethods
