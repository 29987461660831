import React, { useEffect, useState } from 'react'
import { Table } from '../../../Components'
import styles from './style.module.css'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { MdDownload } from 'react-icons/md';
import * as xlsx from 'xlsx';
import ReactGA from 'react-ga';
import DateFilter from '../../../Components/DateFilter';
import { getPastDuesByGroup, resetQueueByUser } from '../../../services/kollectaApi';
import Pagination from '../../../Components/Pagination';

const ManagerPastDues = ({
  data,
  tbSettings,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [originalData, setOriginalData] = useState(data.rows);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(originalData);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [user, setUser] = useState({});

  const resetQueue = async (id) => {
    await resetQueueByUser(id);
  }

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    setUser(userParse);

    if (userParse && userParse.userType === 'operator') {
      resetQueue(userParse.id);
    }
  }, []);

  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null, filter: null });

  const handleDateRangeChange = (range) => {
    setDateRangeFilter(range);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  
  const statusSettings = {
    breking_duel: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "Acordo Quebrado",
    },
    waiting_payment: {
      color: "#7D3300",
      backgroundColor: "#FDE3D1",
      label: "Pendente",
    },
    paid: {
      color: "#2F7D00",
      backgroundColor: "#E2FDD1",
      label: "Acordo quitado",
    },
    viewed: {
      color: "#00577D",
      backgroundColor: "#D1E3FD",
      label: "Acordo consultado",
    },
    generated: {
      color: "#00577D",
      backgroundColor: "#D1E3FD",
      label: "Acordo emitido",
    },
    pending: {
      color: "#00577D",
      backgroundColor: "#D1E3FD",
      label: "Acordo em progresso",
    },
    contacted: {
      color: "#00577D",
      backgroundColor: "#D1E3FD",
      label: "Contato realizado",
    },
    cpc: {
      color: "#00577D",
      backgroundColor: "#D1E3FD",
      label: "CPC",
    },
    new_condition: {
      color: "#7D3300",
      backgroundColor: "#FDE3D1",
      label: "Nova condição",
    },
    no_interest: {
      color: "#7D3300",
      backgroundColor: "#FDE3D1",
      label: "Sem interesse na proposta",
    },
    agreement_broken: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "Acordo quebrado",
    },
    promise_to_pay: {
      color: "#7D3300",
      backgroundColor: "#FDE3D1",
      label: "Promessa de pagamento",
    },
    suspected_fraud: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "Suspeita de fraude",
    },
    indirect_payment: {
      color: "#7D7100",
      backgroundColor: "#FDF6D1",
      label: "Pagamento indireto",
    },
    cpe: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "CPE",
    },
    deceased: {
      color: "#000000",
      backgroundColor: "#D3D3D3",
      label: "Falecido",
    },
    collections_suspended: {
      color: "#4D007D",
      backgroundColor: "#E4D1FD",
      label: "Cobrança suspensa",
    },
    not_return: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "Sem retorno",
    },
    without_terms_payment: {
      color: "#7D0000",
      backgroundColor: "#FDD4D1",
      label: "Sem Cond. Pgto",
    },
  };

  const columnTranslations = {
    id: 'id',
    discountAmount: 'Valor com desconto',
    companyDisplayName: 'Empresa',
    originalAmount: 'Valor original',
    discount: 'Desconto',
    pastDueCode: 'Código da dívida',
    customerName: 'Nome',
    email: 'Email',
    personalNumber: 'CPF/CNPJ',
    cellPhone: 'Número celular',
    entrance: 'Valor de entrada',
    installmentAmount: 'Valor das parcelas',
    installments: 'Número de parcelas',
    status: 'Status',
    viewedDate: 'Data de visualização da dívida',
    createdAt: 'Data de criação no sistema',
  }

  const translateStatus = (status) => {
    if (!status) {
      return '-';
    }
  
    const lowerCaseStatus = status.toLowerCase();
    const statusObject = statusSettings[lowerCaseStatus];
  
    return statusObject?.label || status;
  };

  const filterTranslatedColumns = (item, columnsToExclude) => {
    const filteredItem = {};
    for (let [key, value] of Object.entries(item)) {
        if (!columnsToExclude.includes(key)) {
            filteredItem[key] = value;
        }
    }
    return filteredItem;
  };

  const translateColumns = (item) => {
    const translatedItem = {};
    for (let [key, value] of Object.entries(item)) {
      if (columnTranslations[key]) {
        if (key === 'discountAmount' || key === 'originalAmount' || key === 'installmentAmount') {
          const fixedValue = value / 100;
          translatedItem[columnTranslations[key]] = fixedValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
          
        } else {
          translatedItem[columnTranslations[key]] = value;
        }
      } else {
        translatedItem[key] = value;
      }
    }
    return translatedItem;
  }

  const downloadExcel = (data, columnsToExclude) => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Download em excel'
    });
    const filteredData = data.map(item => filterTranslatedColumns(item, columnsToExclude));
    const translatedData = filteredData.map(item => ({ ...item, status: translateStatus(item.Status) }));
    const worksheetData = translatedData.map(item => {
      const { status: translatedStatus, ...rest } = item;
      return { ...rest, 'Status': translatedStatus };
    });
  
    const worksheet = xlsx.utils.json_to_sheet(worksheetData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Dividas');
    xlsx.writeFile(workbook, 'dividas.xlsx');
  };

  const fetchData = async (page) => {
    const response = await getPastDuesByGroup(page);
    setFilteredData(response.data.rows);
  };

  // ********** useEffcts **********

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const filteredDataResult = originalData.filter((item) => {
      const cleanedValue = item.personalNumber.toLowerCase().replace(/[^\w]/g, '');
      const cleanedSearchValue = searchValue.toLowerCase().replace(/[^\w]/g, '');
      let cleanedStatus = '';
      if (selectedStatus) {
        cleanedStatus = selectedStatus.toLowerCase().replace(/[^\w]/g, '');
      }
      let valid = cleanedValue.includes(cleanedSearchValue) && (!selectedStatus || item.status.toLowerCase().includes(cleanedStatus));
  
      let itemDate;
      if (valid && cleanedStatus === 'promise_to_pay' && item.promisePaymentDate) {
        itemDate = new Date(item.promisePaymentDate);
      } else if (valid) {
        itemDate = new Date(item.updatedAt);
      }
  
      if (itemDate) {
        itemDate.setHours(0, 0, 0, 0);
      }
  
      const startDate = dateRangeFilter.startDate ? new Date(dateRangeFilter.startDate) : null;
      const endDate = dateRangeFilter.endDate ? new Date(dateRangeFilter.endDate) : null;
  
      if (valid && startDate && endDate) {
        if (dateRangeFilter.filter === 'yesterday') {
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);
          valid = startDate.getTime() === endDate.getTime() && itemDate && itemDate.getTime() === yesterday.getTime();
        } else if (dateRangeFilter.filter === 'today') {
          valid = startDate.getTime() === endDate.getTime() && itemDate && itemDate.getTime() === today.getTime();
        } else {
          valid = itemDate >= startDate && itemDate <= endDate;
        }
      } else if (valid && startDate) {
        valid = itemDate && itemDate >= startDate;
      } else if (valid && endDate) {
        valid = itemDate && itemDate <= endDate;
      }
  
      return valid;
    }).sort((a, b) => {
      const aDate = a.promisePaymentDate ? new Date(a.promisePaymentDate) : new Date(a.updatedAt);
      const bDate = b.promisePaymentDate ? new Date(b.promisePaymentDate) : new Date(b.updatedAt);
      return aDate - bDate;
    });
  
    setFilteredData(filteredDataResult);
  }, [searchValue, selectedStatus, originalData, dateRangeFilter]); 
  

  useEffect(() => {
    setOriginalData(data.rows);

  }, [data.rows, dateRangeFilter]);
  
  useEffect(() => {
    const filteredDataResult = originalData.filter((item) => {
      const cleanedValue = item.personalNumber.toLowerCase().replace(/[^\w]/g, '');
      const cleanedSearchValue = searchValue.toLowerCase().replace(/[^\w]/g, '');
      const cleanedStatus = selectedStatus.toLowerCase().replace(/[^\w]/g, '');
      return cleanedValue.includes(cleanedSearchValue) && item.status.toLowerCase().includes(cleanedStatus);
    });
    setFilteredData(filteredDataResult);
  }, [searchValue, selectedStatus, originalData]); 

  useEffect(() => {
      fetchData(currentPage);
  }, [currentPage]);

  return (
    <ReactCSSTransitionGroup
      transitionName="anim"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}
    >

      <div className={styles.top}>
        <h3>Gestão de devedores</h3>
      </div>

      <div className={styles.managerContainer}>

        <div className={styles.contentContainer}>
          <div className={styles.topContent}>
            <div className={styles.leftContent}>
              <div className={styles.titleWithNumber}>
                <h3> Lista de devedores </h3>
                <span>{data?.totalRecords ? filteredData?.length : ''}</span>
              </div>
              <div className={styles.subtitle}>
                <h4> Use os filtros para ver o que precisa </h4>
              </div>
            </div>

            <div className={styles.rightContent}  onBlur={handleClose}>
              <div className={isOpen ? `${styles.selectWrapper} ${styles.active}` : `${styles.selectWrapper}`} 
                  onClick={() => handleClick()}>
                <select value={selectedStatus} onChange={handleStatusChange}  className={`${styles.input}`}>
                    <option value="">Selecione um status</option>
                    <option value="agreement_broken">Acordo quebrado</option>
                    <option value="generated">Acordo emitido</option>
                    <option value="paid">Acordo quitado</option>
                    <option value="pending">Acordo em progresso</option>
                    <option value="viewed">Acordo consultado</option>
                    <option value="collections_suspended">Cobranças suspensas</option>
                    <option value="contacted">Contato realizado</option>
                    <option value="scheduled_contact">Contato agendado</option>
                    <option value="cpc">CPC</option>
                    <option value="cpe">CPE</option>
                    <option value="deceased">Falecido</option>
                    <option value="new_condition">Nova condição</option>
                    <option value="indirect_payment">Pagamento indireto</option>
                    <option value="waiting_payment">Pendente</option>
                    <option value="promise_to_pay">Promessa de pagamento</option>
                    <option value="no_interest">Sem interesse na proposta</option>
                    <option value="not_return">Sem retorno</option>
                    <option value="without_terms_payment">Sem condições de pagamento</option>
                    <option value="suspected_fraud">Suspeita de fraude</option>
                  </select>
              </div>

              <DateFilter onDateChange={handleDateRangeChange} />

                <span className={styles.tbBtnDownload}>
                  <button
                    className={styles.downloadButton}
                    onClick={() => {
                        const excludedColumns = ['updatedAt', 'companyId', 'emailConfirmed'];
                        downloadExcel(filteredData.map(translateColumns), excludedColumns);
                    }}
                  >
                    Baixar Excel
                    <i>
                      <MdDownload />
                    </i>
                  </button>
              </span>
            </div>
          </div>
          <Table data={filteredData} tbSettings={tbSettings} />
          { data && data.totalRecords > 5000 &&
            <Pagination 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              totalItems={data.totalRecords} 
              itemsPerPage={5000}
            />          
          }
        </div>
      </div>
    </ReactCSSTransitionGroup>
  );
};

export default ManagerPastDues;
