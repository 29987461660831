import React from 'react';
import styles from './style.module.css';
import Card from '../Card';
import DateFilter from '../DateFilter';
import { useState } from 'react';
import { useEffect } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';

export function Tabs({ children, activeTab, onChange, styleType, text, noPadding }) {
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null, filter: null });

  const handleDateRangeChange = (range) => {
    setDateRangeFilter(range);
};

  useEffect(() => {
  }, [dateRangeFilter]);

  return (
    <Card noPadding={true}>
      <section className={styles.wrapper}>
        {styleType === 'historic' && <div className={styles.tabsTop}>
          <h4> Histórico de atendimentos </h4>
          <div className={styles.filter}>
            <DateFilter onDateChange={handleDateRangeChange} />
          </div>
        </div>}

        {styleType === 'customer' && <div className={styles.tabsTopReason}>          
          <div className={styles.containerReason}>
            {text && (
              <><i> <AiFillQuestionCircle /></i><span className={styles.reason}> Motivo: {text} </span></>
            ) }
          </div>
        </div>}
        <div className={styles.tabs}>
          {React.Children.map(children, (child, index) => (
            <button
              className={activeTab === index ? styles.active : ''}
              onClick={() => onChange(index)}
            >
              {child.props.label}
            </button>
          ))}
        </div>
        <div className={`${styles.tabsContent} ${noPadding && styles.noPadding}`}>
        {React.Children.map(children, (child, index) =>
            activeTab === index ? React.cloneElement(child, { dateRangeFilter }) : undefined
        )}
        </div>
      </section>
    </Card>
  );
}
export default Tabs;