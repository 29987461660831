import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import ReactGA from 'react-ga';

import styles from './style.module.css';
import LogoK from './kollectanew.png';

import { AiOutlineUpload } from "react-icons/ai";
import { FaChartPie } from "react-icons/fa";
import { IoStatsChartSharp } from "react-icons/io5";
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdGroups, MdKeyboardArrowUp, MdLogout } from "react-icons/md";
import { RiShieldStarFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { resetQueueByUser } from '../../services/kollectaApi';

const Layout = ({
  children,
  logo = true,
  dashBoard = false,
}) => {
  const { pathname } = useLocation()
  let navigate = useNavigate()
  const companySettings = useSelector((state) => state.companySettings.settings)
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [companyIdExists, setCompanyIdExists] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const href = window.location.href;

    if (href.includes('/dashboard/portfolio-analysis') || href.includes('/dashboard/portfolio-analysis-single')) {
      setNavbarOpen(true);
    }

    checkCompanyIdExists();

  }, [pathname, navigate]);

  useEffect(() => {
  const user = localStorage.getItem('user');
  if (user) {
    const userParse = JSON.parse(user);
    setUser(userParse);
  }
  }, []);


  const resetQueue = async (id) => {
    await resetQueueByUser(id);
  }

  const loggout = async () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Sair'
    });
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);

    if (userParse && userParse.userType === 'operator') {
      await resetQueue(userParse.id);
    }
    localStorage.removeItem('token')
    localStorage.removeItem('user.name')
    localStorage.removeItem('user')
    navigate("/login")
  }

  const handleMenuClick = (label) => {
    ReactGA.event({
      category: 'Menu',
      action: 'Clique',
      label: label
    });
  };

  const checkCompanyIdExists = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user['companyId']) {
      const companyIdArray = ['393ac1b9-b91d-42d8-8b1b-b7ed4779c3ad', 'a2c6ef7d-bbfc-48ea-b4f7-82cffb78f613'];
  
      if (companyIdArray.includes(user['companyId'])) {
         return setCompanyIdExists(true);
      }
    }
  }

  const toogleMenu = () => {
    setNavbarOpen(!navbarOpen);
  }

  const Mobile = () => (
    <div className={styles.layout}>
      {logo && (
        <div className={styles.layoutHeader}>
          <img
            className={styles.layoutLogoMobile}
            src={companySettings.logo}
            alt={companySettings.siteUrl}
          />
        </div>
      )}
      {children || 'Tela em construção!'}
    </div>
  )
  
  const Dash = () => (
    <section className={styles.layoutDash}>
      <div className={styles.sidebar}>
        { user && user.userType === 'operator' && (
            <>
              <div className={`${styles.dashLogo} ${styles.dashLogoOperator}`}>
                <img src={LogoK} className={styles.logoDashboard} alt="kollecta.io" />
              </div>
              <ul className={`${styles.menuDash} ${styles.menuDashOperator}`}>
                <li onClick={() => {
                  navigate("/dashboard/home-operator");
                  handleMenuClick('TelaInicial');
                } } className={classNames(styles.menuItem, {
                  [styles.menuItemActivated]: pathname === '/dashboard/home-operator'
                })}>
                  <i className={styles.icon}> <RiShieldStarFill /> </i>
                  Tela inicial</li>

                <li onClick={() => {
                  navigate("/dashboard/manager-pastdues");
                  handleMenuClick('Manager');
                } } className={classNames(styles.menuItem, {
                  [styles.menuItemActivated]: pathname === '/dashboard/manager-pastdues'
                })}>
                  <i className={styles.icon}> <BsPeopleFill /> </i>
                  Gestão de devedores</li>
              </ul>
            </>
          )        
        }
        { user && user.userType === 'supervisor' && (
          <>
            <div className={styles.dashLogo}>
              <img src={LogoK} className={styles.logoDashboard} alt="kollecta.io" />
            </div>
            <ul className={styles.menuDash}>
              <li onClick={() => {
                navigate("/dashboard/home");
                handleMenuClick('Indicadores');
              } } className={classNames(styles.menuItem, {
                [styles.menuItemActivated]: pathname === '/dashboard/home'
              })}>
                <i className={styles.icon}> <FaChartPie /> </i>
                Indicadores</li>

              {companyIdExists &&
                <ul className={classNames(styles.submenus, {
                  [styles.subMenuContainerItemActivated]: (pathname === '/dashboard/portfolio-analysis')
                    || (pathname === '/dashboard/portfolio-analysis-single')
                })}>

                  <li className={classNames(styles.menuItem, styles.staticMenu, {
                    [styles.menuItemActivated]: (pathname === '/dashboard/portfolio-analysis') || (pathname === '/dashboard/portfolio-analysis-single')
                  })} onClick={() => { toogleMenu(); navigate("/dashboard/portfolio-analysis"); handleMenuClick('Análise de dados'); } }>
                    <i className={styles.icon}> <IoStatsChartSharp /> </i>
                    Análise de dados
                    <i className={styles.icon}> {navbarOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />} </i>
                  </li>

                  {navbarOpen &&
                    <><li className={classNames(styles.menuItem, {
                      [styles.menuItemActivated]: pathname === '/dashboard/portfolio-analysis'
                    })} onClick={() => { navigate("/dashboard/portfolio-analysis"); handleMenuClick('Análise em lote'); } }>
                      <span> Análise em lote </span>
                      <i className={styles.icon}> <MdKeyboardArrowRight /> </i>
                    </li><li className={classNames(styles.menuItem, {
                      [styles.menuItemActivated]: pathname === '/dashboard/portfolio-analysis-single'
                    })} onClick={() => { navigate("/dashboard/portfolio-analysis-single"); handleMenuClick('Consulta avulsa'); } }>
                        <span> Consulta Avulsa </span>
                        <i className={styles.icon}> <MdKeyboardArrowRight /> </i>
                      </li></>}

                </ul>}

              <li onClick={() => { navigate("/dashboard/manager-pastdues"); handleMenuClick('gestão de devedores'); } } className={classNames(styles.menuItem, {
                [styles.menuItemActivated]: pathname === '/dashboard/manager-pastdues'
              })}>
                <i className={styles.icon}> <MdGroups /> </i>
                Gestão de devedores
              </li>

              <li onClick={() => { navigate("/dashboard/past-due"); handleMenuClick('Cadastrar dívida'); } } className={classNames(styles.menuItem, {
                [styles.menuItemActivated]: pathname === '/dashboard/past-due'
              })}>
                <i className={styles.icon}> <AiOutlineUpload /> </i>
                Cadastrar dívidas</li>

            </ul></>
          )        
        }

        <div className={styles.logoutContainer}>
          <li onClick={loggout} className={classNames(styles.menuItem, styles.menuItemLogout)}>
            <i className={styles.icon}> <MdLogout /> </i>
           Sair
          </li>
        </div>
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </section>
  )
  const Component = dashBoard ? Dash : Mobile

  return (
    <Component />
  )
}

export default Layout;
