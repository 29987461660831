const status = [
  { value: 'breking_duel', label: 'Acordo Quebrado' },
  { value: 'waiting_payment', label: 'Pendente' },
  { value: 'paid', label: 'Acordo quitado' },
  { value: 'viewed', label: 'Acordo consultado' },
  { value: 'generated', label: 'Acordo emitido' },
  { value: 'pending', label: 'Acordo em progresso' },
  { value: 'agreement_broke', label: 'Acordo quebrado' },
  { value: 'new_condition', label: 'Nova condição' },
  { value: 'promise_to_pay', label: 'Promessa de pagamento' },
  { value: 'scheduled_contact', label: 'Contato agendado' },
  { value: 'contacted', label: 'Contato realizado' },
  { value: 'cpc', label: 'CPC' },
  { value: 'no_interest', label: 'Sem interesse na proposta' },
  { value: 'suspected_fraud', label: 'Suspeita de fraude' },
  { value: 'indirect_payment', label: 'Pagamento indireto' },
  { value: 'cpe', label: 'CPE' },
  { value: 'deceased', label: 'Falecido' },
  { value: 'collections_suspended', label: 'Cobrança suspensa' },
  { value: 'not_return', label: 'Sem retorno' },
  { value: 'without_terms_payment', label: 'Sem Cond. Pgto' },
  { value: 'agreement_broken', label: 'Acordo quebrado' },
]

export default status;
