import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EmailContainer from '../../../Container/Mobile/Email';
import { postSendEmail } from '../../../services/kollectaApi';

const Email = () => {
  const [personalEmail, setPersonalEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()


  const companySettings = useSelector((state) => state.companySettings.settings);

  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const customerName = pathOr(null, ["customerName"], pastDue);  
  const company = pathOr(null, ["companyDisplayName"], pastDue);
  const firstName = customerName && customerName.split(" ")[0];

  const pastDueTransaction = useSelector((state) => state.pastdueTransaction.pastdueTransaction)

  const code = (
    pastDueTransaction.pastDueTransactions
  )

  const renderCode = (
    code.length > 0
      && pathOr("", [0, "boleto_barcode"], code)
  )

  const renderUrl = (
    code.length > 0
      && pathOr("", [0, "boleto_url"], code)
  )

  const handleChange = ({ target }) => {
    setPersonalEmail(target.value)
  }

  const sendEmail = async () => {
    if (!personalEmail) {
      return;
    }
    try {
      const data = {
        email: personalEmail,
        firstName: firstName,
        credor: company,
        boletoUrl: renderUrl,
      }
      const response = await postSendEmail(data);
      navigate('feedback');
    } catch (error) {
      console.error(error);
    }
  }
 
  return (
    <EmailContainer
      handleChange={handleChange}
      personalEmail={personalEmail}
      settings={companySettings}
      sendEmail={sendEmail}
      loading={loading}
    />
  )
}

export default Email
