import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import HomeContainer from '../../../Container/Mobile/Home'
import { getOffers, updatePastDue } from '../../../services/kollectaApi.js'
import { setPastdue } from '../../../Redux/Reducers/PastDue'
import ReactGA from 'react-ga';

const Home = () => {
  const [personalNumber, setPersonalNumber] = useState('')
  const [loading, setLoading] = useState(true)

  const companySettings = useSelector((state) => state.companySettings.settings)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChange = ({ target }) => {
    setPersonalNumber(target.value)
  }

  const goToOffer = async () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Consultar grátis'
    });
    if (!personalNumber) {
      return;
    }
    try {
      const { data: { count = 0, rows = []} } = await getOffers(personalNumber.replace(/\D/g, ''));
      if(count > 0) {
        const pastDueId = rows[0].id;
        const status = { status: "viewed" };
        if (rows[0].status === 'waiting_payment') {
          await updatePastDue(pastDueId, status);
        }

        if (rows[0].status === 'paid') {
          navigate(`${personalNumber.replace(/\D/g, '')}/no-offer`)
          return;
        }

        dispatch(setPastdue(rows[0]))
        navigate(personalNumber.replace(/\D/g, ''))
      } else {
        navigate(`${personalNumber.replace(/\D/g, '')}/no-offer`)
      }
    } catch (error) {
      navigate(`${personalNumber.replace(/\D/g, '')}/no-offer`)
    }
  }

  setTimeout(() => setLoading(false), 3000)
  
  return (
    <HomeContainer
      handleChange={handleChange}
      personalNumber={personalNumber}
      goToOffer={goToOffer}
      settings={companySettings}
      loading={loading}
    />
  )
}

export default Home
