import React from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import styles from './style.module.css';
import Card from '../../Card';
import { formatToBRL } from 'brazilian-values';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPieChartData } from '../../../services/kollectaApi';


const PieChartComponent = () => {
  const [renderChart, setRenderChart] = useState(false);
  const [dataChart, setDataChart] = useState();


const getData = async () => {
  const response = await getPieChartData();
  setDataChart(response.data.data);
}

useEffect(() => {
  getData();
}, []);

useEffect(() => {
}, [dataChart]);



const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul className={styles.legend}>
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ margin: '4px 0' }}>
                  <svg width="13" height="13" style={{ display: 'inline-block', marginRight: '4px' }}>
                      <rect x="0" y="0" width="13" height="13" fill={COLORS[index]} />
                  </svg>
                  <span><b className={styles.piePercentage}>{entry.payload.payload.payload.percentage}%</b>  {entry.payload.payload.payload.status}</span>
              </li>
          ))}
      </ul>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <p className={styles.intro}>{`${formatToBRL(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};

const COLORS = ["#334588", "#08B984", "#7300CF", "#03B4DB", "#FF5C00", "#FF9900", "#DF0000", "#828282"];

useEffect(() => {
  const timer = setTimeout(() => setRenderChart(true), 100); // ajuste o delay conforme necessário
  return () => clearTimeout(timer);
}, []);

return (
  <Card>
    <section className={styles.wrapper}>
      <div className={styles.topContent}>
       <h1> Segmentação da carteira </h1>
       <h3>  </h3>
      </div>
      {renderChart && dataChart && (
        <ResponsiveContainer width="100%" height={500}>
          <PieChart>
            <Pie
                data={dataChart}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={120} 
                fill="#8884d8"
                dataKey="amount"
                labelLine={true}
            >
                {dataChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} offset={20} />

            <Legend
              content={CustomLegend}
              layout='vertical'
              align='left'
              verticalAlign='middle'
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </section>
  </Card>
)
}

export default PieChartComponent;
