import React from 'react';
import { 
  Button, 
  Input, 
  Title, 
  Loading,
} from '../../../Components';
import styles from './style.module.css';
import KollectaLogo from '../../../assets/images/kollectanew.svg';

const Home = ({
  handleChange,
  goToOffer,
  personalNumber,
  settings,
  loading,
}) => {
 return (
    <Loading show={loading}>
      <div className={styles.homeContainer}>
        <div className={styles.homeSize}>
          <div className={styles.layoutHeader}>
            <img 
              className={styles.layoutLogoMobile} 
              src={KollectaLogo} 
              alt={settings.siteUrl} 
            />
          </div>


          <div className={styles.containerLimit}>
            <div style={{ margin: '50px 0 30px 0' }}>
              <Title size="superLarge" color="primary" textAlign="left">
                Suas dívidas pagas agora
              </Title>
            </div>
            <Title size="medium" color="primary" textAlign="left">
              Simples e fácil.
            </Title>

            <Title size="medium" color="primary" textAlign="left">
              De forma 100% digital.
            </Title>
          </div>

          <div style={{ margin: '30px 0 20px 0' }}>
            <Input 
              name="personalNumber" 
              value={personalNumber}
              placeholder="Digite o seu CPF ou CNPJ"
              onChange={handleChange}
              mask="999.999.999-99"
            />
          </div>
          <div style={{ margin: '0 0 50px 0' }}>
            <Button onClick={goToOffer}>
              Consultar grátis
            </Button>
          </div>
        </div>

        <div className={styles.footer}>
          
        </div>
      </div>
    </Loading>
 )
}

export default Home
