import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeaderInternal from '../../../../Components/HeaderInternal';
import CurrentPastDue from '../../../../Components/CurrentPastDue';
import { resetQueueByUser, getByUserScheduledContact, getByUser } from '../../../../services/kollectaApi';
import Tabs from '../../../../Components/Tabs';
import { Tab } from '../../../../Components/Tab';
import TableSimple from '../../../../Components/TableSimple';
import moment from 'moment';
import TargetTracker from '../../../../Components/TargetTracker';

const OperatorHome = ({
  data,
}) => {
  const [user, setUser] = useState({});
  const [scheduledPastDues, setScheduledPastDues] = useState([]);
  const [pastDuesByUser, setPastDuesByUser] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const resetQueue = async (id) => {
    await resetQueueByUser(id);
  }

  const getScheduledpastDues = async () => {
    const data = await getByUserScheduledContact();
    setScheduledPastDues(data.data);
    return data || null;
  }

  const getPastDuesByUser = async () => {
    const data = await getByUser();
    setPastDuesByUser(data.data);
    return data || null;
  }

  const headers = ["Cliente", "Documento", "Credor", "Data", "Hora", "Status", ""];

  const fieldsToDisplay = ["customerName", "personalNumber", "companyDisplayName", "Data", "Hora", "statusRow", "Detalhes"];
    
  const formatField = {
    field: "scheduling", 
    function: (value) => `${moment(value).format("DD/MM/YYYY HH:mm")}`,
  };

const processRows = (originalRows) => {
  return originalRows.map(row => {
      const schedulingDate = moment(row.scheduling);
      const currentDate = moment();

      if (schedulingDate.isAfter(currentDate)) {
        return {
          ...row,
          Data: moment(row.sheduling).format('DD/MM/YYYY'),
          Hora: moment(row.sheduling).format('HH:mm'),
          statusRow: "Dentro do prazo"
        };
      } else {
        return {
          ...row,
          Data: moment(row.sheduling).format('DD/MM/YYYY'),
          Hora: moment(row.sheduling).format('HH:mm'),
          statusRow: "Expirado"
        };
      }
  });
}

  const headersHistory = ["Cliente", "Documento", "Credor", "Última atualização", "Status", ""];

  const fieldsToDisplayHistory = ["customerName", "personalNumber", "companyDisplayName", "updatedAt", "status", "Detalhes"];
    
  const formatFieldHistory = {
      field: "updatedAt", 
      function: (value) => `${moment(value).format("DD/MM/YYYY HH:mm")}`
  };
  
  // useEffects
  useEffect(() => {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    setUser(userParse);

    if (userParse && userParse.userType === 'operator') {
      resetQueue(userParse.id);
      getScheduledpastDues();
      getPastDuesByUser();

      const dateFilterString = localStorage.getItem('dateRangeFilter');
      const dateFilter = dateFilterString ? JSON.parse(dateFilterString) : null;
    
      if (dateFilter) {
          setStartDate(moment(dateFilter.startDate));
          setEndDate(moment(dateFilter.endDate));
      }
    
    }
  }, []);

  useEffect(() => {
  }, [scheduledPastDues, pastDuesByUser]);

	return (
    <div>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
         <HeaderInternal title={user ? user.name : ''} subtitle={user ? user.email : ''} /> 

        <secttion className={styles.grid}>
          <div className={styles.item1}>
            <CurrentPastDue user={user} />
          </div>
          <div className={styles.item2}>
            <TargetTracker user={user} />
          </div>
          <div className={styles.item3}>
          <Tabs activeTab={activeTab} onChange={setActiveTab} styleType='historic'>
            <Tab label="Agendamentos">
            { scheduledPastDues ? <TableSimple headers={headers} rows={processRows(scheduledPastDues)} fieldsToDisplay={fieldsToDisplay} formatFunction={formatField} columns={7} /> : '' }
            </Tab>
            <Tab label="Histórico de atendimentos">
              { pastDuesByUser ? <TableSimple headers={headersHistory} rows={pastDuesByUser} fieldsToDisplay={fieldsToDisplayHistory} formatFunction={formatFieldHistory}  columns={6} /> : '' }
            </Tab>
          </Tabs>
          </div>
        </secttion>      
      </ReactCSSTransitionGroup>
    </div>
	)
}

export default OperatorHome;
