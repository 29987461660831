import React, { useState } from 'react';
import styles from './style.module.css';
import { useEffect } from 'react';
import { parseCurrency } from '../../utils/currency';
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';
import moment from 'moment';

const VerticalTabs = ({ data, type, maxSize }) => {  

  const [currentIndex, setCurrentIndex] = useState(() => {
    if (type !== 'contracts' && data && data.length > 0) {
      return data.findIndex(item => item.installment_number === Math.min(...data.map(d => d.installment_number)));
    }
    return 0;
  });
 
  const goUp = () => {
    if (data && data.length > 1) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    }
  };

  const goDown = () => {
    if (data && data.length > 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }
  };

  const calculateDelayDays = (inputDate) => {
    const providedDate = moment(inputDate);
    const currentDate = moment();
  
    const dayDifference = currentDate.diff(providedDate, 'days');
  
    return dayDifference > 0 ? dayDifference : "-";
  }

  useEffect(() => {
  }, [data]);

  if (type === 'contracts') {
    return (
      <div className={styles.wrapper}>
        {data && data.length > 1 && (
          <div className={`${styles.item} ${styles.itemArrow}`} onClick={goUp}>
            <i><BiSolidUpArrow /></i>
            <div>{data[currentIndex - 1 < 0 ? data.length - 1 : currentIndex - 1]?.contract}</div>
          </div>
        )}
        <div className={`${styles.item} ${styles.main}`}>
          <div className={styles.contentItem}>
            <span>Número</span> <b>{data[currentIndex]?.contract ? data[currentIndex]?.contract : "-"}</b>          
          </div>
  
          <div className={styles.contentItem}>
            <span>Parcela</span><b>{parseCurrency(data[currentIndex]?.originalAmount)}</b>          
          </div>
  
          <div className={styles.contentItem}>
            <span>Vencimento</span><b>{data[currentIndex]?.validDueDate ? moment(data[currentIndex]?.validDueDate).format("DD-MM-YYYY") : "-"}</b>         
          </div>
  
          <div className={styles.contentItem}>
            <span>Atraso</span><b>{calculateDelayDays(data[currentIndex]?.validDueDate)}</b>        
          </div>
  
          <div className={styles.contentItem}>
            <span>Corrigido</span><b>{parseCurrency(data[currentIndex]?.originalAmount)}</b>      
          </div>
        </div>
        {data && data.length > 1 && (
          <div className={`${styles.item} ${styles.itemArrow}`} onClick={goDown}>
            <i><BiSolidDownArrow /></i>
            <div>{data[(currentIndex + 1) % data.length]?.contract}</div>
          </div>
        )}
      </div>
    );
  }

  if (data && data.length > 0) {
    return (
      <div className={styles.wrapper}>
        {data && data.length > 1 && (
          <div className={`${styles.item} ${styles.itemArrow}`} onClick={goUp}>
            <i><BiSolidUpArrow /></i>
            <div></div>
          </div>
        )}
        <div className={`${styles.item} ${styles.main}`}>
          <div className={styles.contentItem}>
            <span>Parcela</span>
            <b>
              {data[currentIndex]?.installment_number ? `${data[currentIndex]?.installment_number}/${maxSize}` : "1/1"}
            </b>          
          </div>
  
          <div className={styles.contentItem}>
            <span>Valor</span><b>{data[currentIndex]?.amount ? parseCurrency(data[currentIndex]?.amount) : "-"}</b>      
          </div>
  
          <div className={styles.contentItem}>
            <span>Vencimento</span><b>{data[currentIndex]?.boleto_dueDate ? moment(data[currentIndex]?.boleto_dueDate).format("DD-MM-YYYY") : "-"}</b>         
          </div>
  
          <div className={styles.contentItem}>
            <span>Status</span><b>{data[currentIndex]?.boleto_dueDate ? (calculateDelayDays(data[currentIndex]?.boleto_dueDate) > 0 || calculateDelayDays(data[currentIndex]?.boleto_dueDate) !== '-') ? "Vencido" : "Dentro do vencimento" : "-"}</b>        
          </div>
        </div>
        {data && data.length > 1 && (
          <div className={`${styles.item} ${styles.itemArrow}`} onClick={goDown}>
            <i><BiSolidDownArrow /></i>
            <div></div>
          </div>
        )}
      </div>
    );
  }

  return <></>
}

export default VerticalTabs;
