import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QRCode from 'qrcode.react'

import { Alert, Button, Layout, Title } from '../../../Components'
import styles from './style.module.css'
import check from '../../../assets/images/checkCopy.svg'
import { pathOr } from 'ramda'
import GoBack from '../../../Components/GoBack'
import ReactGA from 'react-ga';

const paymentMethods = {
  pix: {
    title: 'Código PIX gerado com sucesso!',
    subtitle: 'Utilize o código para realizar o pagamento',
    clipboard: 'Código copiado com sucesso',
    btnText: 'Copiar código',
  },
  boleto: {
    title: 'Boleto gerado com sucesso!',
    subtitle: 'Copie o código do boleto para realizar o pagamento.',
    clipboard: 'Código copiado com sucesso',
    btnText: 'Copiar código do boleto',
  },
  installments:  {
    title: 'O boleto da entrada foi gerado!',
    subtitle: 'Os boletos das outras parcelas serão enviados por e-mail, SMS e WhatsApp quando estiverem próximos ao vencimento. Copie o código do boleto para realizar o pagamento.',
    info: 'Utilize o número do código de barras para realizar o pagamento',
    clipboard: 'Código copiado com sucesso',
    btnText: 'Copiar código do boleto',
  },
}

const Payment = ({
  paymentMethod,
  data,
  installmentDay,
}) => {
  const [copy, setCopy] = useState(false)

  const code = (
    data.pastDueTransactions 
    && data.pastDueTransactions.filter(deal => deal.activated)
  )

  const renderCode = (
    paymentMethod === 'pix' && code.length > 0
      ? pathOr("", [0, "pix_qr_code"], code)
      : pathOr("", [0, "boleto_barcode"], code)
  )
  
  const handleCopy = () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Copiar código de barras'
    });
    setCopy(true)
  }

  return (
    <Layout logo={false}>
      <div className={styles.paymentContainer}>
        {!installmentDay && (
           <>
            <div className={styles.top}>
              <div className={styles.goBackButton}>
                <GoBack />
              </div>
              <div className={styles.topContent}>
                <Title size="superLarge" color='white'>
                {paymentMethod && paymentMethods[paymentMethod].title} 
                </Title>
              </div>
    
              <div className={styles.customImage}>
    
              </div>
            </div>

            <div className={styles.content}>
              <p>
                {paymentMethod && paymentMethods[paymentMethod].subtitle} 
              </p>
            </div>
          </>
        )}


          {installmentDay && (
          <>
            <div className={styles.top}>
              <div className={styles.goBackButton}>
                <GoBack />
              </div>
              <div className={styles.topContent}>
                <Title size="superLarge" color='white'>
                {paymentMethod && paymentMethods.installments.title} 
                </Title>
              </div>

              <div className={styles.customImage}>

              </div>
            </div>

            <div className={styles.content}>
              <p>
                {paymentMethod && paymentMethods.installments.subtitle} 
              </p>
            </div>
          </>
        )}
        <div className={styles.paymentCode}>
            {paymentMethod === "boleto" && (
              renderCode
            )}
          {paymentMethod === "pix" && (
            <QRCode value={renderCode} />
          )}
        </div>
        <div style={{ margin: '20px' }}>
        <CopyToClipboard text={renderCode}>
          <Button onClick={handleCopy}>
          {paymentMethod && paymentMethods[paymentMethod].btnText} 
          </Button>
        </CopyToClipboard>
        </div>
        <Alert show={copy}>
          <img src={check} alt="arrow-right" height="20px" />
            <strong>{paymentMethod && paymentMethods[paymentMethod].clipboard}</strong>
        </Alert>
      </div>
    </Layout>
  )
}

export default Payment
