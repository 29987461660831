import React from 'react'
import { Button, Layout, Title } from '../../../Components'
import styles from './style.module.css'
import HappyLogo from '../../../assets/images/happy-icon.svg';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

const NoOffer = () => {
  let navigate = useNavigate();

  const goToHome = () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Voltar ao início'
    });
    navigate("/offers/customer")
  }
  return (
    <Layout logo={false}>
      <div className={styles.noOfferContainer}>
        <div className={styles.top}>
          <div className={styles.icon}>
            <img 
                className={styles.layoutLogoMobile} 
                src={HappyLogo} 
                alt='Portal Kollecta - Você não possui dívidas' 
            />
          </div>
          <div style={{ margin: '50px 0 30px 0' }}>
            <Title size="superLarge" color="white" textAlign="left">
              Ufa! Você não <br />
              tem dívidas
            </Title>
          </div>

          <Title size="medium" color="white" textAlign="left">
            Não localizamos em nossa base, dívidas nesse CPF.
          </Title>

          <div style={{ margin: '30px 0 0 0' }}>
            <Button onClick={goToHome} color='white'>
              Voltar ao início
            </Button>
            </div>
        </div>

        <div className={styles.footer}>

        </div>
      </div>
    </Layout>
  )
}

export default NoOffer
