import React from 'react';
import styles from './style.module.css';
import classnames from 'classnames';

function Select(props) {
  const { options, value, onChange, classCustom } = props;

  const combinedClasses = `${styles.wrapper} ${classCustom ? styles[classCustom] : ''}`;

  return (
    <div className={combinedClasses}>
      <select value={value} onChange={onChange} defaultValue={''}>
        <option value="" disabled>Selecione</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
