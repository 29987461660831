import React, { useState } from 'react';
import styles from './style.module.css';
import { useEffect } from 'react';
import Tabs from '../Tabs';
import { Tab } from '../Tab';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

const CustomerData = ({ customerData }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
  }, []);

  return (
    <section className={styles.wrapper}>
      <Tabs activeTab={activeTab} onChange={setActiveTab} styleType='customer' text={customerData?.reason} noPadding>
        <Tab label="E-mail">  
          <div className={styles.rows}>          
            <span>
              {customerData?.emailConfirmed ? customerData?.emailConfirmed : customerData?.email } 
            </span>
            {
              customerData?.emails && customerData?.emails.map((email) => (
                <span className={styles.contactSpan}>{ email }</span>
              ))
            }
          </div>
        </Tab>
        <Tab label="Celular"> 
          <div className={styles.rows}>
            <span> {formatPhoneNumber(customerData?.cellPhone) } </span>
            {
              customerData?.phones && customerData?.phones.map((phone) => (
                <span className={styles.contactSpan}>{ formatPhoneNumber(phone) }</span>
              ))
            }
          </div>
        </Tab>
      </Tabs>
    </section>
  )
}

export default CustomerData;
