import React, { useState } from 'react';
import Countdown from 'react-countdown';

import { parseCurrency } from '../../../utils/currency';
import { Button, Layout, Title, RadioButtonOffer } from '../../../Components';
import styles from './style.module.css';
import { pathOr } from 'ramda';
import LogRocket from 'logrocket';
import GoBack from '../../../Components/GoBack';
import MbaLogo from '../../../assets/companiesLogos/mba.svg';

const Offer = ({
  goToOffer,
  data,
  setInstallmentType,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  LogRocket.identify(data.personalNumber);

  const onlyCashPayment = ["02494745225"];

  const CountdownRender = ({
    days, 
    hours, 
    minutes, 
    seconds, 
    completed 
  }) => {
    if(completed) {
      return (
       'Proposta expirada 😔!'
      )
    }
    return (
      `${days} dia ${hours} horas ${minutes} minutos e ${seconds} segundos`
    )
  }
  const customerName = pathOr(null, ["customerName"], data)
  const customerDocument = pathOr(null, ["personalNumber"], data)
  const firstName = customerName && customerName.split(" ")[0]
  
  
  const isOnlyCashPayment = () => {
    let isValid;
    onlyCashPayment.map((document) => {
      if (document === customerDocument) {
        isValid = true;
      }
    });

    return isValid;
  }

  const handleChange = ({target}) => {
    const { value } = target;
    setIsChecked(value);
    setInstallmentType(value)
  }

  return (
    <Layout logo={false}>
      <div className={styles.offerContainer}>
        <div className={styles.top}>

          <div className={styles.goBackButton}>
            <GoBack />
          </div>
          <div className={styles.topContent}>
            <Title size="superLarge" color='white'>
              {firstName}
            </Title>
            <Title size="small" color="white">
              a <strong> {data.companyDisplayName} </strong> disponibilizou <br /> 
              as seguintes ofertas:
            </Title>
          </div>

          <div className={styles.customImage}>

          </div>
        </div>


        <div className={styles.offerCard}>
          <div className={styles.offerInfo}>
            <div>
              <Title>
              Valor de pendência a pagar
              </Title>
              <Title color="primary" size="superLarge">
                {parseCurrency(data.originalAmount)}
              </Title>
            </div>
            <div>
              { data && data.companyId === '44aeda9c-25f2-435b-9d6f-4204860e5783' &&
                <img src={MbaLogo} alt="Kollecta" className={styles.compayLogo}></img>              
              }
            </div>
          </div>

          <Title color="primary" size="medium">
            Confira nossas ofertas:
          </Title>
            <RadioButtonOffer name="payment-offer" value="avista" onChange={handleChange} checked={isChecked === "avista" && true}>
              <Title color="primary" align="left">
                <div className={styles.container}>
                  <div className={styles.left}>
                    <span> Pagamento à vista de: </span> <br />
                    ({data.discount}% de desconto)
                  </div>
                  <div className={styles.right}>
                    <span className={styles.amountDebtDiscount}>{parseCurrency(data.discountAmount)} </span>                    
                  </div>
                </div>
              </Title>
            </RadioButtonOffer>          

          {!isOnlyCashPayment() && data.entrance !== 0 &&
            <RadioButtonOffer name="payment-offer" value="parcelado" onChange={handleChange}  checked={isChecked === "parcelado" && true}>
               <Title color="primary" align="left">
                <div className={styles.container}>
                  <div className={styles.left}>
                    <span> Parcelado: </span> <br />
                    Entrada de {parseCurrency(data.entrance)}:
                  </div>
                  <div className={styles.right}>
                    <span className={styles.amountDebtDiscount}><strong>{data.installments} x</strong> {parseCurrency(data.installmentAmount)} </span>                    
                  </div>
                </div>
              </Title>
            </RadioButtonOffer>
          }


          <div className={styles.countdown}>
              <strong>Esta oferta esta válida durante </strong>
              <Countdown
                date={Date.now() + 130000000}
                renderer={CountdownRender}
              />
          </div>

          <div style={{ margin: "20px 0" }}>
            <Button onClick={goToOffer} color="dark">
              Prosseguir
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Offer;
