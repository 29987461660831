import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import Login from './Pages/Dashboard/Login';
import Home from './Pages/Mobile/Home';
import Offer from './Pages/Mobile/Offer';
import PaymentMethods from './Pages/Mobile/PaymentMethods';
import Payment from './Pages/Mobile/Payment';
import PastDueDate from './Pages/Mobile/PastDueDate';
import NoOffer from './Pages/Mobile/NoOffer';
import HomeDashPage from './Pages/Dashboard/Home';
import PastDue from './Pages/Dashboard/PastDue';
import ManagerPastDues from './Pages/Dashboard/ManagerPastDues';
import Email from './Pages/Mobile/Email';
import Feedback from './Pages/Mobile/Feedback';
import BilletMethods from './Pages/Mobile/BilletMethods';

import { Layout } from './Components';
import ProtectedRoute from './protectRoute';
import PortfolioAnalysis from './Container/Dashboard/PortfolioAnalysis';
import PortfolioAnalysisSingle from './Container/Dashboard/PortfolioAnalysis/single';
import PastDueDetails from './Pages/Dashboard/PastDueDetails';

// Operator
import OperatorHome from './Pages/Dashboard/Operator/Home';


const App = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="dashboard">
        <Route path="home" element={<ProtectedRoute><Layout dashBoard><HomeDashPage /></Layout></ProtectedRoute>} />
        <Route path="past-due" element={<ProtectedRoute><Layout dashBoard><PastDue /></Layout></ProtectedRoute>} />
        <Route path="portfolio-analysis" element={<ProtectedRoute><Layout dashBoard><PortfolioAnalysis /></Layout></ProtectedRoute>} />
        <Route path="portfolio-analysis-single" element={<ProtectedRoute><Layout dashBoard><PortfolioAnalysisSingle /></Layout></ProtectedRoute>} />
        <Route path="manager-pastdues" element={<ProtectedRoute><Layout dashBoard><ManagerPastDues /></Layout></ProtectedRoute>} />
        <Route path="details" element={<ProtectedRoute><Layout dashBoard><PastDueDetails /></Layout></ProtectedRoute>} />

        {/* Operator */}
        <Route path="home-operator" element={<ProtectedRoute><Layout dashBoard><OperatorHome /></Layout></ProtectedRoute>} />

      </Route>
      <Route path="offers" >
        <Route path="customer" element={<Home />} />
        <Route path="customer/:id" element={<Offer />} />
        <Route path="customer/:id/no-offer" element={<NoOffer />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId" element={<PastDueDate />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId/installment-day/:installmentDay" element={<PaymentMethods />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId/installment-day/:installmentDay/method/:method/billetmethods" element={<BilletMethods />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId/installment-day/:installmentDay/method/:method/billetmethods/method/:method/" element={<Payment />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId/installment-day/:installmentDay/method/:method/billetmethods/email" element={<Email />} />
        <Route path="customer/:id/past-due-date/:pastDueOption/payment/:offerId/installment-day/:installmentDay/method/:method/billetmethods/email/feedback" element={<Feedback />} />

        <Route path="customer/:id/payment-methods/:offerId/installments/:installment" element={<PaymentMethods />} />
        <Route path="customer/:id/payment-methods/:offerId/installments/:installment/method/:method/billetmethods" element={<BilletMethods />} />
        <Route path="customer/:id/payment-methods/:offerId/installments/:installment/method/:method/billetmethods/method/:method/" element={<Payment />} />
        <Route path="customer/:id/payment-methods/:offerId/installments/:installment/method/:method/billetmethods/email" element={<Email />} />
        <Route path="customer/:id/payment-methods/:offerId/installments/:installment/method/:method/billetmethods/email/feedback" element={<Feedback />} />
      </Route>
      <Route path="*" to="/" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}

export default App
