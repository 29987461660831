const statusSettings = [
  { value: 'contacted', label: 'Contato realizado' },
  { value: 'cpc', label: 'CPC' },
  { value: 'no_interest', label: 'Sem interesse na proposta' },
  { value: 'suspected_fraud', label: 'Suspeita de fraude' },
  { value: 'indirect_payment', label: 'Pagamento indireto' },
  { value: 'cpe', label: 'CPE' },
  { value: 'deceased', label: 'Falecido' },
  { value: 'collections_suspended', label: 'Cobrança suspensa' },
  { value: 'not_return', label: 'Sem retorno' },
  { value: 'without_terms_payment', label: 'Sem Cond. Pgto' },
]

export default statusSettings;
