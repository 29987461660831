import React from 'react';
import styles from './style.module.css';
import Card from '../Card';
import { BsCalendarMinus, BsCalendarCheck } from 'react-icons/bs';
import { MdPhoneIphone, MdOutlineMarkEmailRead } from 'react-icons/md';
import { HiCurrencyDollar } from 'react-icons/hi';
import ActionButton from '../ActionButton';
import { getQueueNext, updateAllPastDueData } from '../../services/kollectaApi';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatDate } from '../../utils/formatDate';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { parseCurrency } from '../../utils/currency';
import parseStatus from '../../utils/statusParse';
import LoadingOverlay from '../LoadingOverlay';

const CurrentPastDue = ({ user }) => {
  const [pastDueData, setPastDueData] = useState(false);
  const [loading, setLoading] = useState(true);

  const setTemp = async () => {
    await updateAllPastDueData(pastDueData.id, { inAttendance: true, user: user.id });
  }

  const getPastDue = async () => {
    const pastDue = await getQueueNext();
    setPastDueData(pastDue ? pastDue.data : null);    
    setLoading(false);
    return pastDue;
  }

  useEffect(() => {
    getPastDue();  
  }, []);

  useEffect(() => {
    if (pastDueData) {
      setTemp();
    }
  }, [pastDueData]);

  if (!pastDueData) {
    return (
    <section className={styles.wrapper}>
      <h4> Ficha atual </h4>
      <Card>
        <LoadingOverlay isVisible={loading}/>
        { !loading && 
          <div className={styles.container}>
            <div className={styles.content}>
              Não existe mais dívidas na fila. Atualize a página para verificar novamente
            </div>
          </div>        
        }
      </Card>
    </section>
    )
  }
  return (
    <section className={styles.wrapper}>
      <h4> Ficha atual </h4>
      <Card>
        <LoadingOverlay isVisible={loading}/>
        <div className={styles.container}>
          <div className={styles.content}>
            <h3> { pastDueData.customerName } </h3>

            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><BsCalendarMinus width={16} height={16} /></i><span> Última interação: <b>{ formatDate(pastDueData.updatedAt) }</b> </span>
              </div>
            </div>

            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><BsCalendarCheck width={16} height={16} /></i><span> Último status: { parseStatus(pastDueData.status) } </span>
              </div>
            </div>
            
            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><MdPhoneIphone width={16} height={16} /></i><span> Telefone prioritário: <b>{ formatPhoneNumber(pastDueData.cellPhone) }</b> </span>
              </div>
            </div>
            
            
            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><MdOutlineMarkEmailRead width={16} height={16} /></i><span> E-mail prioritário: <b>{ pastDueData.emailConfirmed || pastDueData.email }</b> </span>
              </div>
            </div>

            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><HiCurrencyDollar width={16} height={16} /></i><span> Valor aberto: <b>{ parseCurrency(pastDueData.originalAmount) }</b> </span>
              </div>
            </div>
            
            
            <div className={styles.contentItem}>
              <div className={styles.spanContent}>
                <i><HiCurrencyDollar /></i><span> Credor: <b>{ pastDueData.companyDisplayName }</b> </span>
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <ActionButton type='refresh' data={pastDueData} />
            <ActionButton  data={pastDueData} />
          </div>

        </div>
      </Card>
    </section>
  )
}

export default CurrentPastDue;
